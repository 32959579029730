<template>
  <div class="content">
    <div class="contentmain">
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/qiyeindex' }"
            >企业</el-breadcrumb-item
          >
          <el-breadcrumb-item>企业详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="gs-info" v-if="companyDetail">
        <div class="gi-left">
          <div class="gl-pic">
            <img
              v-if="companyDetail.companyAvatar"
              :src="
                'https://freemen.work/user/file/view/' +
                companyDetail.companyAvatar
              "
            />
            <img v-else src="../../assets/logo.png" />
          </div>
          <div class="gl-info font14">
            <div class="font20">{{ companyDetail.shortName }}</div>
            <div class="" style="display: flex">
              <span>{{
                companyDetail.companyType
                  ? companyDetail.companyType.itemText + " |"
                  : ""
              }}</span>
              <span>{{
                companyDetail.companySize
                  ? companyDetail.companySize.itemText
                  : ""
              }}</span>
            </div>
            <div
              class=""
              style="display: flex; flex-flow: row wrap"
              v-if="companyDetail.companyWelfare"
            >
              <div
                class=""
                style="padding: 0 5px 0 0"
                v-for="(item, index) in companyDetail.companyWelfare"
                :key="index">
                <span>{{ item.itemText }}</span>
                <span v-if="index < companyDetail.companyWelfare.length - 1">|</span>
              </div>
            </div>
          </div>
        </div>
        <el-divider direction="vertical"></el-divider>
        <a href="#tiao" style="text-decoration: none">
          <div class="gi-right">
            <img src="../../assets/images/common/position-icon.png" />
            <div class="font14">该公司全部职位</div>
          </div>
        </a>
      </div>
      <div class="companybox">
        <div class="synopsis">
          <div class="s-tit font18">公司简介</div>
          <el-card class="box-card synopsis-mian">
            <div class="contenttext font14">
              <div class="">
                {{
                  companyDetail.companyDescription
                    ? companyDetail.companyDescription
                    : "暂无公司简介"
                }}
              </div>
            </div>
            <el-divider></el-divider>
            <div class="caozuo" @click="blackCompany">
              <img src="../../assets/images/common/pingbi.png" />
              <div class="font14">
                {{ companyDetail.isBlack == 1 ? "取消屏蔽" : "屏蔽该公司" }}
              </div>
            </div>
          </el-card>
        </div>
        <div class="other">
          <div class="title-list">
            <div
              class="c-tit font18"
              :class="choosenum == 1 ? 'active' : ''"
              @click="choosenum = 1"
            >
              公司相册
            </div>
            <div
              class="c-tit font18"
              :class="choosenum == 2 ? 'active' : ''"
              @click="choosenum = 2"
            >
              公司视频
            </div>
          </div>
          <div class="photobox" v-if="choosenum == 1">
            <div class="" style="width: 380px" v-if="picList.length">
              <el-carousel height="210px">
                <el-carousel-item v-for="item in picList" :key="item">
                  <img
                    style="width: 100%; height: 100%"
                    :src="'https://freemen.work/user/file/view/' + item"
                  />
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="" style="width: 380px" v-else>
              <div class="nodata">
                <img src="../../assets/images/common/search-no.png" />
                <div class="">暂无相册</div>
              </div>
            </div>
          </div>
          <div class="photobox" v-if="choosenum == 2">
            <div
              class=""
              style="width: 400px"
              v-if="videoList.length > 0 && videoList[0] != ''"
            >
              <div style="height: 280px">
                <!-- <div v-for="item in videoList" :key="item"> -->
                <div class="imgshowmain">
                  <video
                    style="width: 100%; height: 280px"
                    v-bind:src="
                      'https://freemen.work/user/file/view/' + videoList[0]
                    "
                    class="avatar video-avatar"
                    controls="controls"
                  >
                    您的浏览器不支持视频播放
                  </video>
                  <div class="videoBox">
                    <img
                      class="bofang"
                      src="../../assets/images/common/bofang-img.png"
                      @click="videoshow(videoList[0])"
                    />
                  </div>
                </div>
                <!-- </div> -->
              </div>
            </div>
            <div class="" style="width: 380px" v-else>
              <div class="nodata">
                <img src="../../assets/images/common/search-no.png" />
                <div class="">暂无视频</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="addressbox">
        <div class="s-tit font18">公司地址</div>
        <el-card class="box-card">
          <div class="location font16">
            <i class="el-icon-location-outline"></i>
            <span
              >{{ companyDetail.address }} .
              {{ companyDetail.companyBuild }}</span
            >
          </div>

          <el-amap class="ditubox" :zoom="zoom" :center="center">
            <el-amap-marker
              v-for="(marker, index) in markers"
              :position="marker.position"
              :events="marker.events"
              :vid="index"
              :icon="marker.icon"
              v-bind:key="index"
            >
            </el-amap-marker>
          </el-amap>
          <!-- <div class="ditubox" id="ditubox">

					</div> -->
        </el-card>
      </div>
      <div class="positionbox" id="tiao">
        <div class="s-tit font18">招聘职位</div>
        <el-card
          class="box-card p-list"
          style="margin-bottom: 20px"
          v-for="(item, index) in jobList"
          :key="index"
        >
          <div class="p-listbox" @click="toDetail(item.id)">
            <div class="pl-left font14">
              <div class="l-one">
                <div class="font16">
                  {{ item.jobName }}
                  <span v-if="item.jobType == 1">Free</span>
                </div>
              </div>
              <div class="l-two">
                {{ item.city }} |
                {{
                  item.educationType.length
                    ? item.educationType[0].itemText
                    : "不限"
                }}
                | {{ item.expLow }}-{{ item.expHigh }}年 |
                {{ (item.salaryLow / 1000) | numFilter }}K-{{ (item.salaryHigh / 1000) | numFilter }}K
              </div>
              <div class="l-three" v-if="item.jobType != 2">
                项目周期：{{
                  item.startDate ? item.startDate.replace(/00:00:00/g, "") : "/"
                }}
                —
                {{ item.endDate ? item.endDate.replace(/00:00:00/g, "") : "/" }}
              </div>
            </div>
            <el-divider direction="vertical"></el-divider>
            <div class="pl-right font14">
              <div class="time">{{ item.updateDate }}发布</div>
              <div class="synum">剩余招聘:{{ item.jobFree }}</div>
              <div class="czbtn">
                <el-button
                  type="primary"
                  @click.stop="wanshan(item.companyUserId, item.id)"
                  >投递该职位</el-button
                >
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <el-dialog
        :visible.sync="dialogVisible"
        width="700px"
        :show-close="false"
        :modal-append-to-body="true"
        :append-to-body="true"
        :center="true"
      >
        <div class="registersuccess">
          <span class="closeicon" @click="dialogVisible = false">
            <i class="el-icon-error"></i>
          </span>
          <div class="gou_img">
            <img src="../../assets/images/common/position-nodata.png" />
          </div>
          <div
            class="congratulations font18"
            :style="resData.code == 10200 ? '' : 'color:red'"
          >
            {{
              resData.code == 10200
                ? "简历投递成功"
                : "投递失败：" + resData.message
            }}
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";
export default {
  data() {
    return {
      choosenum: 1,
      companyDetail: {},
      arriveCoor: [],
      arrive: "",
      jobList: [],
      picList: [],
      videoList: [],
      zoom: 16,
      center: [116.411572, 39.90807],
      mapStyle: "amap://styles/8b6be8ec497009e17a708205348b899a", //设置地图样式
      markers: [],
      dialogVisible: false,
      userInfoDetail: {},   // 个人简历信息
      resData: {
        code: 0,
        message: "",
      },
    };
  },
  created() {
    this.getCompanyDetail();
    // this.mapDraw(),
    this.getUserInfo();
    this.getJobList();
  },
  mounted() {
    // console.log(document.getElementById('main_id').scrollTop)
    document.getElementById('main_id').scrollTop=0
  },
  filters: {
    numFilter(value) {
      let transformVal = Number(value).toFixed(2)
      let realVal = transformVal.substring(0, transformVal.length - 1)

      return Number(realVal)
    }
  },
  methods: {
    toDetail(id) {
      this.$router.push({
        path: "/positiondetail",
        query: {
          id: id,
          userJobId: this.$route.query.userJobId,
          enderType: "search",
        },
      });
    },
    getUserInfo() {
      this.$api.viewResume("get").then((res) => {
        // 获取简历详情
        // "userCv":{},
        // "educationList":[],
        // "projectExpList":[],
        // "honorCertList":[],
        // "userJobList":[],
        // "workExpList":[],
        // "languageList":[],
        // "user":{}
        // console.log("companyDetail.$preview.res, res.code =", res);
        if (res.code === 10200) {
          this.userInfoDetail = res.data;
        }
      });
    },
    getCompanyDetail() {
      this.$api
        .getCompanyDetail("get", { companyId: this.$route.query.id })
        .then((res) => {
          // console.log("companyDetail.$getCompanyDetail.res, res.code =", res);
          this.companyDetail = res.data;
          if(res.data.album){
            this.picList = res.data.album.split(",");
          }
          if(res.data.resUrl){
            this.videoList = res.data.resUrl.split(",");
          }
          this.arriveCoor.push(res.data.lat);
          this.arriveCoor.push(res.data.lng);
          if(res.data.lng){
            setTimeout(() => {
              this.mapDraw(this.arriveCoor);
            }, 1000);
          }
        });
    },
    blackCompany() {
      let config = {
        ids: this.$route.query.id,
        status: this.companyDetail.isBlack == 1 ? 0 : 1,
      };
      this.$api.blackCompany("post", config).then(() => {
        this.companyDetail.isBlack = config.status;
        this.$message({
          message: "操作成功",
          type: "success",
        });
      });
    },
    getJobList() {
      this.$api
        .getCompanyJobList("get", { companyId: this.$route.query.id })
        .then((res) => {
          this.jobList = res.data;
        });
    },
    wanshan(companyUserId, jobId) {
      this.dialogVisible = true;
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      // console.log("job detail ---- userInfo = ", userInfo);
      if (userInfo["idStatus"] != 1) {
        this.resData = {
          code: 1,
          message: "您尚未通过实名认证！",
          // message: "实名状态审核中",
        };
        return;
      }
      if (userInfo["cvStatus"] != 1) {
        this.resData = {
          code: 1,
          message: "正在审核您的个人资料，请确认您的求职意向！",
          // message: "简历状态审核中",
        };
        return;
      }
      if(this.userInfoDetail.userJobList.length === 0){
        this.resData = {
          code: 0,
          message: "请完善您的简历信息，求职意向不能为空！",
        };
        return;
      }
      if (this.userInfoDetail.workExpList.length === 0) {
        this.resData = {
          code: 0,
          message: "请完善您的简历信息，工作经历不能为空！",
        };
        return;
      }
      if (this.userInfoDetail.educationList.length === 0) {
        this.resData = {
          code: 0,
          message: "请完善您的简历信息，教育经历不能为空！",
        };
        return;
      }
      if (this.userInfoDetail.projectExpList.length === 0) {
        this.resData = {
          code: 0,
          message: "请完善您的简历信息，项目经历不能为空！",
        };
        return;
      }
      let userJobId = this.userInfoDetail.userJobList[0].id;
      let config = {
        companyJobId: jobId,
        userJobId: userJobId,
        companyId: this.$route.query.id - 0,
        companyUserId: companyUserId,
      };
      this.$api.sendResume("post", config).then((res) => {
        this.resData = res;
        // this.$message({
        //   message: res.code == 10200 ? "操作成功" : res.message,
        //   type: res.code == 10200 ? "success" : "error",
        // });
      });
    },
    videoshow(data) {
      //播放视频
      let data1 = {
        resUrl: data,
      };
      Bus.$emit("videodstuts", {
        show: true,
        data: data1,
      });
    },
    mapDraw(arriveCoor) {
      let markers = [];
      let basePosition = [arriveCoor[1], arriveCoor[0]];
      markers.push({
        position: basePosition,
        icon: "", //图片ip
        imageSize: "20px",
        offset: [0, -50],
      });

      this.markers = markers;
      this.center = basePosition;
    },
    mapCoor(lnglatXY) {
      // 查询坐标
      var _this = this;
      AMap.service("AMap.Geocoder", function () {
        //回调函数
        var geocoder = new AMap.Geocoder({});
        geocoder.getAddress(lnglatXY, function (status, result) {
          if (status === "complete" && result.info === "OK") {
            //获得了有效的地址信息:
            _this.arrive = result.regeocode.formattedAddress;
          } else {
            _this.arrive = "暂无位置";
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.imgshowmain:hover {
  .el-icon-error {
    display: flex !important;
  }
}
.imgshowmain {
  position: relative;
  .video-avatar {
    width: 400px;
    height: 280px;
  }
  .video-avatar::-webkit-media-controls {
    display: none !important;
  }
  .videoBox {
    width: 400px;
    height: 280px;
    position: absolute;
    top: 0;
    left: 0;
  }
  img {
    width: 400px;
    height: 280px;
  }
  .bofang {
    position: absolute;
    width: 67px;
    height: 63px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .el-icon-error {
    width: 30px;
    height: 30px;
    top: 5px;
    right: 5px;
    position: absolute;
    font-size: 30px;
    color: #dddddd;
    display: none;
  }
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

/deep/.el-card {
  border-radius: 0px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
}

.content {
  position: relative;
  margin-bottom: 180px;
  // transform: translate(8px, 0px);
}
.breadcrumb {
  margin: 30px 0;
}
.el-breadcrumb {
  font-size: 14px;
}
.gs-info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 30px 30px 30px;
  background-color: #444c5f;
  /deep/.el-divider {
    background: rgb(248 247 252 / 10%);
  }
  /deep/.el-divider--vertical {
    height: 60px;
  }
  .gi-left {
    display: flex;
    align-items: center;
    flex: 1;
    .gl-pic {
      margin-right: 10px;
      img {
        width: 70px;
        height: 70px;
      }
    }
    .gl-info {
      color: #c5c5c5;
      line-height: 2;
      div:first-child {
        // color: #ffffff;
      }
      span {
        margin: 0 3px;
      }
    }
  }
  .gi-right:hover {
    cursor: pointer;
  }
  .gi-right {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    div {
      padding-bottom: 8px;
      color: #ffffff;
    }
    img {
      width: 57px;
      height: 57px;
      margin-right: 5px;
    }
  }
}
.s-tit {
  color: #111111;
  margin: 30px 0;
}
.companybox {
  display: flex;

  .synopsis {
    display: flex;
    flex-direction: column;
    flex: 1;

    .synopsis-mian {
      min-height: 400px;
      /deep/.el-card__body {
        padding: 30px;
      }
    }
    .contenttext {
      color: #666666;
      line-height: 30px;
    }
    .caozuo:hover {
      cursor: pointer;
    }
    .caozuo {
      display: flex;
      align-items: center;
      color: #111111;
      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
  }
  .other {
    width: 440px;
    // background-color: #0077AA;
    margin-left: 20px;
    .photobox {
      height: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
      .video-avatar {
        height: 100%;
        width: 100%;
      }
      .video-avatar::-webkit-media-controls {
        display: none !important;
      }
      .nodata {
        text-align: center;
        img {
          width: 257px;
          height: 216px;
        }
        div {
          color: #111111;
          margin-top: 20px;
        }
      }
    }
    .title-list {
      display: flex;
      align-items: center;
      .c-tit:hover {
        cursor: pointer;
        color: #00bcff;
      }
      .c-tit {
        color: #111111;
        margin: 30px 40px 30px 0;
      }
      .active {
        position: relative;
      }
      .active:after {
        position: absolute;
        content: "";
        width: 72px;
        height: 3px;
        bottom: -8px;
        background: #00bcff;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.addressbox {
  /deep/.el-card__body {
    padding: 0;
  }
  .location {
    padding: 15px 20px;
    .el-icon-location-outline {
      color: #00bcff;
      margin-right: 5px;
      font-size: 18px;
    }
    span {
      color: #111111;
    }
  }
  .ditubox {
    height: 340px;
    background-color: #409eff;
  }
}

.positionbox {
  .p-list:hover {
    transform: scale(1.03);
    background-color: rgba(0, 0, 0, 0.001);
    cursor: pointer;
  }
  .p-list {
    .p-listbox {
      display: flex;
      align-items: center;
      /deep/.el-divider {
        background: #dddddd;
      }
      /deep/.el-divider--vertical {
        height: 24px;
      }
      .pl-left {
        display: flex;
        flex: 1;
        // align-items: center;
        flex-direction: column;
        line-height: 2;
        .l-one {
          display: flex;
          align-items: center;
          // justify-content: space-between;
          div {
            color: #111111;
            span {
              background-color: #e9f9ff;
              color: #00bcff;
              padding: 1px 10px;
              margin-left: 10px;
              font-size: 14px;
            }
          }
        }
        .l-two {
          color: #666666;
        }
        .l-three {
          color: #666666;
        }
      }
      .pl-right {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        color: #666666;
        .time {
        }
        .synum {
          margin: 0 70px;
          span {
            color: #111111;
          }
        }
        .czbtn {
          /deep/ .el-button {
            border-radius: 0px;
            width: 100px;
            height: 36px;
            background-color: #00bcff;
            border: none;
            color: #ffffff;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

.registersuccess {
  padding: 40px 0;
  position: relative;
  .gou_img {
    text-align: center;
    img {
      height: 180px;
    }
  }
  .congratulations {
    text-align: center;
    color: #111111;
    margin-top: 20px;
  }
  .closeicon {
    position: absolute;
    top: -40px;
    right: 0;
    font-size: 26px;
    color: #f3f3f3;
  }
}
</style>
